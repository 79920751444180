const { createApp } = require('vue')
import App from './App.vue'
import createRouter from './router'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA_KhHEJ-HgOBHigLY-rT2vjRBM8YWLhZY",
  authDomain: "aiteading.firebaseapp.com",
  projectId: "aiteading",
  storageBucket: "aiteading.appspot.com",
  messagingSenderId: "587094549899",
  appId: "1:587094549899:web:405e61bb0fbf852c023b8e",
  measurementId: "G-8RSSJWLK47"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

createApp(App)
  .use(createRouter())
  .mount('#app')
