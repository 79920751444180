import { createRouter, createWebHistory } from 'vue-router/dist/vue-router.esm-bundler'
import Home from './views/Home.vue'

export default () => createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/article/:name',
      name: 'Article',
      component: () => import('./views/Article.vue')
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('./views/About.vue')
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('./views/Privacy.vue')
    },
    {
      path: '/license',
      name: 'License',
      component: () => import('./views/License.vue')
    },
  ]
})
